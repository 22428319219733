import slugify from '../../../custom_slugify'

document.addEventListener('DOMContentLoaded', function () {
  var algoliasearch = require("algoliasearch");
  var client = algoliasearch("LQ3I6AI662", "b24a64c990a929f357ba1201792a3c27");
  var index = client.initIndex("cities");

  var inputs = document.querySelectorAll('.js-algolia-input');

  inputs.forEach(function(input) {
    var container = input.closest('.js-search-container');
    var spinner = container.querySelector('.js-spinner');
    var blockAlgolia = container.querySelector('.js-algoliaBloc');
    var activityType = container.querySelector('.js-activityType').textContent.trim();

    input.addEventListener('input', function () {
      blockAlgolia.classList.remove('hidden');
      spinner.classList.remove('hidden');
      var inputValue = input.value.trim();

      if (inputValue === '') {
        blockAlgolia.classList.add('hidden');
        spinner.classList.add('hidden');
        return;
      }

      index.search(inputValue, { hitsPerPage: 8 })
        .then(function ({ hits }) {
          blockAlgolia.innerHTML = '';
          spinner.classList.add('hidden');

          hits.forEach(function (hit) {
            var cityElement = document.createElement('div');
            cityElement.textContent = hit.name;
            cityElement.classList.add('cursor-pointer', 'p-3', 'border-gray-100', 'border-b', 'hover:bg-blue-50');
            blockAlgolia.appendChild(cityElement);

            cityElement.addEventListener('click', function () {
              spinner.classList.remove('hidden');
              blockAlgolia.classList.add('hidden');
              if (activityType === 'undertaker') {
                window.location.href = `/${hit.department_code}/${slugify(hit.name.toLowerCase())}`;
              } else if (activityType === 'crematorium') {
                window.location.href = `/crematoriums/${hit.department_code}`;
              }
            });
          });
        })
        .catch(function (err) {
          console.error(err);
          spinner.style.display = 'none'; // Masquage du spinner en cas d'erreur
        });
    });
  });
});
